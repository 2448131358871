import React from "react"
// import Helmet from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"
import Header from "./Header"
// import ScreenSaverDvd from "./ui/ScreenSaverDvd"
import i18n from "../../config/i18n"

// require("../styles/index.scss")
// const logo = require("../images/owl-logo-white.svg")

const WrapperContext = React.createContext()

const Layout = ({ children, pageContext: { locale, slug } }) => {
    const { options } = useStaticQuery(query)
    const localized = i18n[locale]
    // console.log(slug)
    return (
        <WrapperContext.Provider
            value={{ locale, i18n, localized, options, slug }}
        >
            <div id="page">
                <Header isHome={slug === undefined} />
                <main>{children}</main>
                {/* <ScreenSaverDvd src={logo} width={316} height={36} /> */}
            </div>
        </WrapperContext.Provider>
    )
}

export { WrapperContext, Layout }

const query = graphql`
    query {
        options: prismicOptions {
            ..._prismicOptions
        }
    }
`
