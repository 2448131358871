import React, { useState, useEffect, useContext } from "react"
//import { StaticQuery, graphql } from "gatsby"
import { useStaticQuery, graphql, Link } from "gatsby"
import PubSub from "pubsub-js"
import { WrapperContext } from "./Layout"
import Burger from "./ui/Burger"
// import { ReactSVG } from 'react-svg'

// const logoBlack = require("../images/owl-logo.svg")
const logoWhite = require("../images/owl-logo-white.svg")

const Header = props => {
    const { options } = useStaticQuery(query)
    const _WrapperContext = useContext(WrapperContext)
    const [active, setActive] = useState(false)

    const { locale, i18n } = _WrapperContext
    const { isHome } = props

    const localized = i18n[locale]
    let menu = []
    if (localized) {
        // console.log(localized["projects"])
        menu = [
            { title: localized["projects"], to: "projects" },
            { title: localized["about"], to: "about" },
            { title: localized["team"], to: "team" },
            { title: localized["contact"], to: "contact" },
        ]
    }

    useEffect(() => {
        const tokenOpen = PubSub.subscribe("BURGER", (e, d) => {
            // console.log(d)
            setActive(d)
        })

        return () => {
            PubSub.unsubscribe(tokenOpen)
        }
    }, [active])

    const _logoClick = () => {
        // console.log("_logoClick")
        PubSub.publish("PROJECT.CLOSE")
        const home = document.querySelector(".home")
        const { top, left } = home.getBoundingClientRect()
        PubSub.publish("SCROLL_BY", { top: top, left: left })
    }

    const _onClick = e => {
        const to = e.target.dataset.to
        // console.log(to)
        const section = document.querySelector("." + to)
        const { top, left } = section.getBoundingClientRect()
        // console.log(left)
        PubSub.publish("BURGER.CLOSE")
        PubSub.publish("SCROLL_BY", { top: top, left: left, element: section })
    }

    return (
        <>
            <header className={active ? "is-menu" : ""}>
                <div className="row between-xs">
                    <div className="col-xs">
                        <Link to="/" onClick={_logoClick}>
                            {isHome ? (
                                <h1>
                                    <img
                                        className="logo"
                                        src={logoWhite}
                                        alt={options.data.title.text}
                                        width="158"
                                        height="18"
                                    />
                                </h1>
                            ) : (
                                <img
                                    className="logo"
                                    src={logoWhite}
                                    alt={options.data.title.text}
                                    width="158"
                                    height="18"
                                />
                            )}
                        </Link>
                        {/* <div className="logo">logo</div> */}
                    </div>
                    <div className="col-xs">
                        <div className="text-right">
                            <Burger />
                        </div>
                    </div>
                </div>
            </header>

            <div className={"modal-nav "}>
                <nav>
                    <ul>
                        {menu.map((li, i) => (
                            <li
                                key={i}
                                className="fXXL serif"
                                onClick={_onClick}
                                data-to={li.to}
                            >
                                {li.title}
                            </li>
                        ))}
                    </ul>
                </nav>
            </div>
        </>
    )
}

export default Header

const query = graphql`
    query {
        options: prismicOptions {
            ..._prismicOptions
        }
    }
`
// class Header extends Component {
//     render() {
//         //console.log(this.props)
//         const {
//             isHome,
//             options
//         } = this.props

//         // const {
//         //     projects
//         // } = this.props
//         // const menu = ["PROJECTS", ]
//         return (
//             <header>
//                 <div className="row between-xs">
//                     <div className="col-xs">
//                         <Link to="/">
//                             {isHome
//                             ? <h1><img src={logo} alt={options.title.text} width="158" height="18" /></h1>
//                             : <img src={logo} alt={options.title.text} width="158" height="18" />
//                             }
//                         </Link>
//                     </div>
//                     <div className="col-xs">
//                         <div className="text-right">
//                             <Burger />
//                         </div>
//                         <div className="modal-nav">
//                             <nav>
//                                 <ul>
//                                     {/* {projects.edges.map((edge,i) => (
//                                         <li key={i}>
//                                             <Link to={'/project/'+edge.node.uid}>
//                                             {edge.node.data.title.text}
//                                             </Link>
//                                         </li>
//                                     ))} */}
//                                 </ul>
//                             </nav>
//                         </div>

//                     </div>
//                 </div>
//             </header>
//         );
//     }
// }

// export default (props) => (
//     <StaticQuery
//         query={graphql`
//             query  {
//                 options: prismicOptions {
//                     ..._prismicOptions
//                 }
//             }
//         `}
//         render={data => (
//             <Header
//             //lang={props.lang}
//             //template={props.template}
//             options={data.options.data}
//             {...props}
//             // projects={data.projects}
//             />
//         )}
//     />
// )
